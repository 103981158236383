.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
    padding: 1rem;
    animation: fadeIn 0.3s ease;
}

.modal-content {
    background: var(--background);
    border-radius: 12px;
    width: 90%;
    max-width: 1200px;
    max-height: 90vh;
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    overflow: hidden;
    position: relative;
    animation: slideUp 0.3s ease;
    height: auto;
    max-height: 90vh;
}

.modal-close {
    position: absolute;
    padding: 0; /* Fixes modal close button styling on iPhones*/
    height: 2rem;
    width: 2rem;
    top: 0.5rem;
    right: 0.5rem;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    border: none;
    cursor: pointer;
    z-index: 1;
}

.modal-icon {
    width: 100%;
    height: 100%;
    transition: background-color 0.3s ease;
    background-color: white;
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-image: url('@assets/cross.svg');
}

.modal-icon:hover {
    background-color: #f01e2c;
}

.modal-gallery {
    position: relative;
    background: var(--background);
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.modal-gallery img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 2rem;
}

.gallery-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: var(--background45);
    color: var(--text);
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.gallery-nav:hover {
    background: var(--background70);
    transform: translateY(-50%) scale(1.1);
}

.gallery-nav.prev { left: 1rem; }
.gallery-nav.prev.single { display: none; }
.gallery-nav.next { right: 1rem; }
.gallery-nav.next.single { display: none; }

.gallery-dots {
    position: absolute;
    bottom: 1rem;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    z-index: 2;
}

.modal-details {
    flex: 1;
    padding: 2rem;
    overflow-y: auto;
}

.modal-details h2 {
    color: var(--text);
    font-size: 1.75rem;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin: 0 0 1rem;
}

.modal-tags {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-bottom: 2rem;
}

.modal-tag {
    background: var(--primary35);
    padding: 0.25rem 0.75rem;
    border-radius: 15px;
    font-size: 0.8rem;
    color: var(--text);
}

.modal-description h3 {
    margin: 1.5rem 0 1rem;
    color: var(--text);
    font-weight: 600;
    letter-spacing: 0.5px;
}

.modal-description p {
    color: var(--text90);
    line-height: 1.6;
    margin-bottom: 1rem;
}

.modal-description ul {
    color: var(--text90);
    line-height: 1.6;
    padding-left: 1.5rem;
    margin-bottom: 1.5rem;
}

.modal-links {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    flex-wrap: wrap;
}

.modal-button {
    padding: 0.8rem 1.5rem;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 500;
    transition: all 0.3s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    flex: 1;
    text-align: center;
    gap: unset;
}

.modal-button img {
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
    margin-top: 0.1rem;
}

.modal-button:hover {
    transform: translateY(-2px);
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideUp {
    from { 
        opacity: 0;
        transform: translateY(20px);
    }
    to { 
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .modal-overlay {
        padding: 0.5rem;
    }

    .modal-content {
        grid-template-columns: 1fr;
        width: 100%;
        max-height: 98vh;
        height: auto;
        display: flex;
        flex-direction: column;
    }

    .modal-gallery {
        height: 30vh;
        min-height: 200px;
        max-height: 40vh;
        aspect-ratio: auto;
    }

    .modal-gallery img {
        padding: 1rem;
    }

    .modal-details {
        padding: 1rem;
        height: auto;
        max-height: 45vh;
        overflow-y: auto;
        flex: 1;
    }

    .modal-links {
        gap: 0.75rem;
    }

    .modal-button {
        padding: 0.6rem 1rem;
        min-width: 100px;
        font-size: 0.9rem;
    }

    .modal-button img {
        width: 16px;
        height: 16px;
    }
}

@media (max-height: 600px) {
    .modal-gallery {
        height: 30vh;
        min-height: 150px;
    }

    .modal-details {
        max-height: 50vh;
    }
}

@media (max-width: 480px) {
    .modal-button {
        flex: 1 1 100%;
    }
}

/* Add these new styles */
.modal-image-container {
    position: relative;
    background-color: rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    transition: opacity 0.3s ease;
}

.modal-image-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #666;
    font-size: 1rem;
}

.gallery-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--primary60);
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0 4px;
    transition: all 0.3s ease;
}

.gallery-dot.active {
    background: var(--primary);
    transform: scale(1.2);
}