.footer {
    background-color: var(--background5);
    padding: 1.25rem 15%;
}

.footer__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
}

.footer__copyright {
    color: #636e72;
    font-size: 0.9rem;
    margin: 0;
    font-weight: 400;
}

.footer__social-links {
    display: flex;
    gap: 2rem;
}

.footer__social-links a {
    color: #636e72;
    text-decoration: none;
    transition: color 0.3s ease;
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0.2px;
}

.footer__social-links a:hover {
    color: #0984e3;
}

.footer__social-links {
    display: flex;
    gap: 1rem;
}

.social-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-icon img {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
}

.social-icon:hover img {
    transform: scale(1.1);
}

@media (max-width: 768px) {
    .footer {
        padding: 1.25rem 5%;
    }

    .footer__content {
        flex-direction: column-reverse;
        gap: 1.25rem;
        text-align: center;
    }

    .footer__social-links {
        gap: 1.5rem;
    }

    .social-icon img {
        width: 22px;
        height: 22px;
    }
}