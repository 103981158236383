.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 4rem;
    background: var(--background5);
    backdrop-filter: blur(30px);
    z-index: 1000;
}

.navbar__desktop {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    max-width: 100vw;
    padding: 0 15vw;
}

.navbar__brand {
    text-decoration: none;
    color: var(--text);
}

.navbar__logo {
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.navbar__menu {
    display: flex;
    gap: 2rem;
}

.navbar__menu-item {
    position: relative;
    text-decoration: none;
    color: var(--text);
    font-size: 1.1rem;
    font-weight: 400;
    letter-spacing: 1px;
    padding: 0.5rem 0;
    transition: color 0.3s ease;
}

.navbar__menu-item::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: var(--primary90);
    transition: width 0.3s ease;
}

.navbar__menu-item:hover::after,
.navbar__menu-item.active::after {
    width: 100%;
}

.navbar__menu-item:hover,
.navbar__menu-item.active {
    color: var(--primary90);
}

/* Mobile Navigation */
.navbar__mobile {
    display: none;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 0 5%;
}

.navbar__hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 24px;
    height: 20px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.navbar__hamburger span {
    width: 100%;
    height: 2px;
    background-color: var(--text);
    transition: all 0.3s ease;
    display: block;
}

.navbar__hamburger.active span:nth-child(1) {
    transform: translateY(9px) rotate(45deg);
}

.navbar__hamburger.active span:nth-child(2) {
    opacity: 0;
}

.navbar__hamburger.active span:nth-child(3) {
    transform: translateY(-9px) rotate(-45deg);
}

.navbar__mobile-menu {
    position: fixed;
    top: 3.97rem;
    left: -100%;
    width: 85vw;
    height: calc(100vh - 4rem);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem 5%;
    transition: left 0.3s ease;
    visibility: hidden;
    z-index: 1000;
}

.navbar__mobile-menu.active {
    left: 0;
    visibility: visible;
}

.navbar__mobile-item {
    text-decoration: none;
    color: var(--text);
    font-size: 2rem;
    font-weight: 400;
    padding: 1.2rem 0;
    width: 100%;
    text-align: left;
    transition: color 0.3s ease;
    position: relative;
}

.navbar__mobile-item::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0.8rem;
    width: 0;
    height: 2px;
    background-color: var(--primary90);
    transition: width 0.3s ease;
}

.navbar__mobile-item:hover::after,
.navbar__mobile-item.active::after {
    width: 40px;
}

.navbar__mobile-item:hover,
.navbar__mobile-item.active {
    color: var(--primary90);
}

/* Add an overlay when menu is open */
.navbar__mobile-overlay {
    position: fixed;
    top: 3.97rem;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--background5);
    backdrop-filter: blur(30px);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
    z-index: 999;
}

.navbar__mobile-overlay.active {
    opacity: 1;
    visibility: visible;
}

@media (max-width: 1440px) {
    .navbar__desktop {
        padding: 0 10vw;
    }
}

@media (max-width: 768px) {
    .navbar__desktop {
        display: none;
    }

    .navbar__mobile {
        display: flex;
    }
}
