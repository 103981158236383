.about-container {
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    background-color: var(--background);
}

.about-content {
    flex: 1;
}

.about-section {
    display: flex;
    gap: 4rem;
    padding: 6rem 15%;
    align-items: center;
}

.about-image {
    flex: 0 0 400px;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem 0;
    aspect-ratio: 3/4;
}

.about-image img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: block;
    transition: transform 0.3s ease;
    object-fit: cover;
}

.about-image:hover img {
    transform: scale(1.03);
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.1)
    );
}

.about-text {
    flex: 1;
    padding: 1rem 0;
}

.about-text h1 {
    font-weight: 400;
    font-size: 3rem;
    letter-spacing: 1px;
    color: var(--text);
    margin: 0 0 0.5rem;
}

.about-subtitle {
    font-size: 1.2rem;
    color: var(--primary90);
    margin-bottom: 2rem;
    font-weight: 500;
}

.about-description p {
    color: var(--text);
    line-height: 1.6;
    margin-bottom: 1rem;
    font-size: 1.1rem;
}

.quick-info {
    position: relative;
    z-index: 1;
    margin-top: 3rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    background: #2a2b44;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.info-item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.info-item .label {
    font-size: 0.9rem;
    color: var(--primary);
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.info-item .value {
    font-size: 1.1rem;
    color: var(--text70);
    word-break: break-word;
}

.quick-info-container {
    position: relative;
    margin-top: 2rem;
}

.peeking-icons {
    position: absolute;
    width: 90%;
    height: 0;
    bottom: calc(100% + 50px);
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
}

.peek-icon-wrapper {
    position: relative;
    width: 48px;
    height: 48px;
    transform-origin: bottom center;
    transition: all 0.3s ease;
}

.peek-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
}

.peek-icon-wrapper.gamepad {
    transform: translateY(24px) rotate(-8deg);
}

.peek-icon-wrapper.bado {
    transform: translateY(24px) rotate(8deg);
}

.peek-icon-wrapper.lol {
    transform: translateY(24px) rotate(10deg);
}

.peek-icon-wrapper.travel {
    transform: translateY(24px) rotate(8deg);
}

.peek-icon-wrapper.esports {
    transform: translateY(24px) rotate(-8deg);
}

.peek-icon-wrapper:hover {
    transform: translateY(-5px) rotate(0deg) !important;
}

.chat-bubble {
    position: absolute;
    background: #2b2c43;
    border-radius: 12px;
    padding: 8px 12px;
    font-size: 0.9rem;
    color: var(--text);
    top: -40px;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.chat-bubble::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid white;
}

.peek-icon-wrapper:hover .chat-bubble {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) translateY(0);
}

.flag-icon {
    width: 20px;
    height: 15px;
    vertical-align: middle;
    margin: 0 2px;
    display: inline-block;
    border-radius: 2px;
}

@media (max-width: 1440px) {
    .about-section {
        padding: 4rem 10%;
    }
}

@media (max-width: 1024px) {
    .about-section {
        flex-direction: column;
        gap: 3rem;
    }

    .about-image {
        flex: 0 0 auto;
        max-width: 400px;
        width: 100%;
        margin: 0 0;
    }

    .about-text {
        padding: 0;
    }
}

@media (max-width: 768px) {

    .about-text h1 {
        font-size: 2.5rem;
    }
    
    .subtitle {
        font-size: 1.1rem;
    }

    .about-description p {
        font-size: 1rem;
    }

    .quick-info {
        padding: 1.5rem;
        gap: 1.5rem;
    }

    .peeking-icons {
        bottom: calc(100% + 40px);
    }

    .peek-icon-wrapper {
        width: 40px;
        height: 40px;
    }
    
    .chat-bubble {
        font-size: 0.8rem;
        padding: 6px 10px;
    }
}
