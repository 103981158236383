.project {
    background-color: transparent;
    padding: 0 0 4rem;
    flex: 1;
}

.project__content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin: 0 auto;
    transition: all 0.3s ease;
    justify-items: center;
}

.project-card {
    position: relative;
    height: auto;
    width: 100%;
    animation: fadeIn 0.5s ease;
    border-radius: 12px;
    overflow: hidden;
    border: 0.1em solid rgba(0, 0, 0, 0);
}

.project-card__inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background: var(--secondary20);
    overflow: hidden;
}

.project-card:hover {
    border:0.1em solid var(--primary70)
}

.project-card:hover .project-card__inner {
    transform: scale(1.02);
    box-shadow: 0 8px 16px rgba(74, 137, 220, 0.06);
}

.project-card__image {
    width: 100%;
    height: 280px;
    overflow: hidden;
}

.project-card__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.project-card:hover .project-card__image img {
    transform: scale(1.05);
}

.project-card__title {
    margin: 0.75rem 0 0;
    color: var(--text);
    font-size: 1.25rem;
    letter-spacing: 0.5px;
    font-weight: 400;
    padding: 0 1rem;
}

.project-card__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--background95);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}

.project-card:hover .project-card__overlay {
    opacity: 1;
    visibility: visible;
}

.project-card__tags--static {
    position: relative;
    padding: 1rem 1rem;
    z-index: 1;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    max-width: calc(100% - 2rem);
}

.project-card__tags--overlay {
    display: flex;
    gap: 0.5rem;
    padding: 0 1rem;
    flex-wrap: wrap;
    justify-content: center;
    transform: translateY(-20px);
    opacity: 0;
    transition: all 0.3s ease;
}

.project-card:hover .project-card__tags--overlay {
    transform: translateY(0);
    opacity: 1;
}

.project-card__tag {
    background: var(--primary35);
    padding: 0.25rem 0.75rem;
    border-radius: 15px;
    font-size: 0.8rem;
    color: white;
}

.project-card__tags--overlay .project-card__tag {
    background: var(--primary35);
    color: var(--text);
    text-shadow: none;
    border: none;
}

.project-card__tags--static .project-card__tag {
    transition: transform 0.3s ease;
}

.project-card__tags--static .project-card__tag:hover {
    transform: translateY(-2px);
}

.project-card__description {
    color: var(--text);
    line-height: 1.6;
    text-align: center;
    margin: 0;
    font-size: 0.95rem;
    transform: translateY(20px);
    opacity: 0;
    transition: all 0.3s ease;
    padding: 0 1rem;
}

.project-card:hover .project-card__description {
    transform: translateY(0);
    opacity: 1;
    transition-delay: 0.1s;
}

.project-card__link {
    display: inline-block;
    padding: 0.5rem 1.25rem;
    cursor: pointer;
    background-color: var(--primary80);
    color: white;
    text-decoration: none;
    border-radius: 25px;
    transition: all 0.3s ease;
    font-size: 0.9rem;
    border: none;
    font-weight: 500;
    transform: translateY(20px);
    opacity: 0;
}

.project-card:hover .project-card__link {
    transform: translateY(0);
    opacity: 1;
    transition-delay: 0.2s;
}

.project-card__link:hover {
    background-color: var(--primary);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(74, 137, 220, 0.15);
}

@media (min-width: 768px) {
    .project__content {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}

@media (min-width: 1200px) {
    .project__content {
        grid-template-columns: repeat(3, 1fr);
    }
}

.project__filter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 3rem;
    flex-wrap: wrap;
    padding: 0 1rem;
}

.filter-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
}

.filter-label {
    font-size: 0.85rem;
    color: var(--text);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
}

.filter-tags {
    display: flex;
    gap: 0.75rem;
    flex-wrap: wrap;
    justify-content: center;
}

.filter-tag {
    padding: 0.5rem 1.25rem;
    border-radius: 25px;
    border: 1px solid rgba(74, 137, 220, 0.2);
    background: transparent;
    color: var(--primary);
    cursor: pointer;
    font-size: 0.9rem;
    transition: all 0.3s ease;
    text-transform: capitalize;
}

.filter-tag:hover {
    background: rgba(74, 137, 220, 0.05);
}

.filter-tag.active {
    background: var(--primary);
    color: white;
    border-color: var(--primary);
}

.filter-divider {
    display: flex;
    align-items: center;
    height: 50px;
}

.filter-divider span {
    width: 1px;
    height: 100%;
    background: linear-gradient(
        to bottom,
        transparent,
        rgba(74, 137, 220, 0.2),
        transparent
    );
}

@media (max-width: 1600px) {
    .project__content {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}

@media (max-width: 768px) {
    .project__filter {
        flex-direction: column;
        gap: 1.5rem;
    }

    .filter-divider {
        height: 1px;
        width: 150px;
    }

    .filter-divider span {
        width: 100%;
        height: 1px;
        background: linear-gradient(
            to right,
            transparent,
            rgba(74, 137, 220, 0.2),
            transparent
        );
    }

    .filter-tag {
        padding: 0.4rem 1rem;
        font-size: 0.85rem;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.project-card:hover .project-card__tags--static {
    opacity: 0;
    transition: opacity 0.2s ease;
}

