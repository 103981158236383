@import './theme.css';

/* Section */
.section {
    padding: 2rem 15vw 0rem;
    background-color: var(--background);
    position: relative;
}

/* Section Titles */
.section-title {
    display: inline-block;
    position: relative;
    font-size: 3rem;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 4rem 0 4rem 0;
}

.section-title::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 60px;
    height: 3px;
    background-color: var(--primary);
}

/* Buttons */
.primary-button, .secondary-button {
    padding: 0.8rem 2rem;
    border-radius: 4px;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.3s ease;
    min-width: 120px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.primary-button {
    background-color: var(--primary85);
    color: var(--text);
}

.primary-button:hover {
    background-color: var(--primary);
    transform: translateY(-2px);
}

.secondary-button {
    border: 2px solid var(--primary60);
    color: var(--text);
    gap: 8px;
}

.secondary-button:hover {
    background-color: var(--primary85);
    color: white;
    transform: translateY(-2px);
}

@media (max-width:1440px) {
    .section {
        padding: 2rem 10vw 0rem;
    }
}

@media (max-width: 768px) {
    /* Section */
    .section {
        padding: 4rem 5%;
        overflow: hidden;
    }

    .section-title {
        font-size: 2.5rem;
    }

    /* Buttons */
    .primary-button, .secondary-button {
        min-width: 140px;
        padding: 0.7rem 1.5rem;
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .primary-button, .secondary-button {
        width: 100%;
        min-width: unset;
    }
}