.skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
}

.skill-card {
    background-color: var(--secondary10);
    padding: 1.5rem;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.skill-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.skill-icon {
    width: 48px;
    height: 48px;
    margin-bottom: 1.5rem;
    transition: transform 0.3s ease;
    background-color: var(--primary);
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
}

.skill-icon.code {
    mask-image: url('@assets/skill-icons/code.svg');
}

.skill-icon.gamepad {
    mask-image: url('@assets/skill-icons/gamepad.svg');
}

.skill-icon.server {
    mask-image: url('@assets/skill-icons/server.svg');
}

.skill-icon.bot {
    mask-image: url('@assets/skill-icons/bot.svg');
}

.skill-icon.tetris {
    mask-image: url('@assets/skill-icons/tetris.svg');
}

.skill-icon.globe {
    mask-image: url('@assets/skill-icons/globe.svg');
}

.skill-card:hover .skill-icon {
    transform: scale(1.1);
}

.skill-card h4 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: var(--primary);
}

.skill-card p {
    color: var(--text);
    line-height: 1.6;
}

/* Add new mobile scroll controls */
.scroll-controls {
    display: none;
}

@media (max-width: 2000px) {
    .skills-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        gap: 2rem;
    }
}

@media (max-width: 1600px) {
    .skills-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 2rem;
    }
}

@media (max-width: 1205px) {
    .skills-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 2rem;
    }
}

@media (max-width: 768px) {
    .skills-grid {
        display: flex;
        overflow-x: scroll;
        scroll-behavior: smooth;
        scroll-snap-type: x mandatory;
        overscroll-behavior: contain;
        gap: 1rem;
        padding: 0.25rem 0 1rem; /* Fixes card getting cutoff when tapping on it on mobile*/
    }

    .skills-grid::-webkit-scrollbar {
        /* display: none; */
        overflow-x: scroll;
    }

    .skill-card {
        flex: 0 0 60%;
        scroll-snap-align: center;
    }

    .scroll-controls {
        display: block;
        position: absolute;
        top: 90%;
        width: 90%;
        left: 0;
        transform: translateY(-50%);
        pointer-events: none;
        padding: 0 5%;
    }

    .scroll-arrow {
        position: absolute;
        scale: 1.5;
        width: 40px;
        height: 40px;
        background: none;
        border: none;
        color: var(--text);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: auto;
        transition: all 0.3s ease;
    }

    .scroll-arrow:hover {
        transform: scale(1.1);
    }

    .scroll-arrow.left {
        left: 5%;
    }

    .scroll-arrow.right {
        right: 5%;
    }

    .scroll-arrow:disabled {
        background: #ccc;
        cursor: not-allowed;
        opacity: 0.5;
    }
} 