/* Color Theme */
/* Light Theme */
:root{
  --text: white;
  --background: #22223b;
  --primary: #8980f5;
  --secondary: #4a4e69;
  --accent: #0a1129;
  --text5: color-mix(in srgb, var(--text) 5%, transparent);
  --text10: color-mix(in srgb, var(--text) 10%, transparent);
  --text15: color-mix(in srgb, var(--text) 15%, transparent);
  --text20: color-mix(in srgb, var(--text) 20%, transparent);
  --text25: color-mix(in srgb, var(--text) 25%, transparent);
  --text30: color-mix(in srgb, var(--text) 30%, transparent);
  --text35: color-mix(in srgb, var(--text) 35%, transparent);
  --text40: color-mix(in srgb, var(--text) 40%, transparent);
  --text45: color-mix(in srgb, var(--text) 45%, transparent);
  --text50: color-mix(in srgb, var(--text) 50%, transparent);
  --text55: color-mix(in srgb, var(--text) 55%, transparent);
  --text60: color-mix(in srgb, var(--text) 60%, transparent);
  --text65: color-mix(in srgb, var(--text) 65%, transparent);
  --text70: color-mix(in srgb, var(--text) 70%, transparent);
  --text75: color-mix(in srgb, var(--text) 75%, transparent);
  --text80: color-mix(in srgb, var(--text) 80%, transparent);
  --text85: color-mix(in srgb, var(--text) 85%, transparent);
  --text90: color-mix(in srgb, var(--text) 90%, transparent);
  --text95: color-mix(in srgb, var(--text) 95%, transparent);
  --background5: color-mix(in srgb, var(--background) 5%, transparent);
  --background10: color-mix(in srgb, var(--background) 10%, transparent);
  --background15: color-mix(in srgb, var(--background) 15%, transparent);
  --background20: color-mix(in srgb, var(--background) 20%, transparent);
  --background25: color-mix(in srgb, var(--background) 25%, transparent);
  --background30: color-mix(in srgb, var(--background) 30%, transparent);
  --background35: color-mix(in srgb, var(--background) 35%, transparent);
  --background40: color-mix(in srgb, var(--background) 40%, transparent);
  --background45: color-mix(in srgb, var(--background) 45%, transparent);
  --background50: color-mix(in srgb, var(--background) 50%, transparent);
  --background55: color-mix(in srgb, var(--background) 55%, transparent);
  --background60: color-mix(in srgb, var(--background) 60%, transparent);
  --background65: color-mix(in srgb, var(--background) 65%, transparent);
  --background70: color-mix(in srgb, var(--background) 70%, transparent);
  --background75: color-mix(in srgb, var(--background) 75%, transparent);
  --background80: color-mix(in srgb, var(--background) 80%, transparent);
  --background85: color-mix(in srgb, var(--background) 85%, transparent);
  --background90: color-mix(in srgb, var(--background) 90%, transparent);
  --background95: color-mix(in srgb, var(--background) 95%, transparent);
  --primary5: color-mix(in srgb, var(--primary) 5%, transparent);
  --primary10: color-mix(in srgb, var(--primary) 10%, transparent);
  --primary15: color-mix(in srgb, var(--primary) 15%, transparent);
  --primary20: color-mix(in srgb, var(--primary) 20%, transparent);
  --primary25: color-mix(in srgb, var(--primary) 25%, transparent);
  --primary30: color-mix(in srgb, var(--primary) 30%, transparent);
  --primary35: color-mix(in srgb, var(--primary) 35%, transparent);
  --primary40: color-mix(in srgb, var(--primary) 40%, transparent);
  --primary45: color-mix(in srgb, var(--primary) 45%, transparent);
  --primary50: color-mix(in srgb, var(--primary) 50%, transparent);
  --primary55: color-mix(in srgb, var(--primary) 55%, transparent);
  --primary60: color-mix(in srgb, var(--primary) 60%, transparent);
  --primary65: color-mix(in srgb, var(--primary) 65%, transparent);
  --primary70: color-mix(in srgb, var(--primary) 70%, transparent);
  --primary75: color-mix(in srgb, var(--primary) 75%, transparent);
  --primary80: color-mix(in srgb, var(--primary) 80%, transparent);
  --primary85: color-mix(in srgb, var(--primary) 85%, transparent);
  --primary90: color-mix(in srgb, var(--primary) 90%, transparent);
  --primary95: color-mix(in srgb, var(--primary) 95%, transparent);
  --secondary5: color-mix(in srgb, var(--secondary) 5%, transparent);
  --secondary10: color-mix(in srgb, var(--secondary) 10%, var(--secondary) 10%);
  --secondary15: color-mix(in srgb, var(--secondary) 15%, transparent);
  --secondary20: color-mix(in srgb, var(--secondary) 20%, transparent);
  --secondary25: color-mix(in srgb, var(--secondary) 25%, transparent);
  --secondary30: color-mix(in srgb, var(--secondary) 30%, transparent);
  --secondary35: color-mix(in srgb, var(--secondary) 35%, transparent);
  --secondary40: color-mix(in srgb, var(--secondary) 40%, transparent);
  --secondary45: color-mix(in srgb, var(--secondary) 45%, transparent);
  --secondary50: color-mix(in srgb, var(--secondary) 50%, transparent);
  --secondary55: color-mix(in srgb, var(--secondary) 55%, transparent);
  --secondary60: color-mix(in srgb, var(--secondary) 60%, transparent);
  --secondary65: color-mix(in srgb, var(--secondary) 65%, transparent);
  --secondary70: color-mix(in srgb, var(--secondary) 70%, transparent);
  --secondary75: color-mix(in srgb, var(--secondary) 75%, transparent);
  --secondary80: color-mix(in srgb, var(--secondary) 80%, transparent);
  --secondary85: color-mix(in srgb, var(--secondary) 85%, transparent);
  --secondary90: color-mix(in srgb, var(--secondary) 90%, transparent);
  --secondary95: color-mix(in srgb, var(--secondary) 95%, transparent);
  --accent5: color-mix(in srgb, var(--accent) 5%, transparent);
  --accent10: color-mix(in srgb, var(--accent) 10%, transparent);
  --accent15: color-mix(in srgb, var(--accent) 15%, transparent);
  --accent20: color-mix(in srgb, var(--accent) 20%, transparent);
  --accent25: color-mix(in srgb, var(--accent) 25%, transparent);
  --accent30: color-mix(in srgb, var(--accent) 30%, transparent);
  --accent35: color-mix(in srgb, var(--accent) 35%, transparent);
  --accent40: color-mix(in srgb, var(--accent) 40%, transparent);
  --accent45: color-mix(in srgb, var(--accent) 45%, transparent);
  --accent50: color-mix(in srgb, var(--accent) 50%, transparent);
  --accent55: color-mix(in srgb, var(--accent) 55%, transparent);
  --accent60: color-mix(in srgb, var(--accent) 60%, transparent);
  --accent65: color-mix(in srgb, var(--accent) 65%, transparent);
  --accent70: color-mix(in srgb, var(--accent) 70%, transparent);
  --accent75: color-mix(in srgb, var(--accent) 75%, transparent);
  --accent80: color-mix(in srgb, var(--accent) 80%, transparent);
  --accent85: color-mix(in srgb, var(--accent) 85%, transparent);
  --accent90: color-mix(in srgb, var(--accent) 90%, transparent);
  --accent95: color-mix(in srgb, var(--accent) 95%, transparent);
}

:root[data-theme="dark"] {
--text: #f6f4f4;
--background: #050001;
--primary: #88c4fc;
--secondary: #2d4371;
--accent: #d6ddf5;
}