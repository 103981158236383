.hero-section {
    position: relative;
    min-height: calc(90vh - 4rem);
    display: flex;
    align-items: center;
    padding: 5vh 15vw 0;
    background: var(--background);
    justify-content: space-between;
}

.hero-text {
    max-width: 600px;
}

.hero-text h1 {
    font-size: 3rem;
    font-weight: 400;
    margin-bottom: 1rem;
    line-height: 1.2;
}

.hero-text h2 {
    font-size: 2.5rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
    color: var(--text);
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.highlight {
    color: var(--primary);
}

.hero-subtitle {
    font-weight: 400;
    letter-spacing: 0.5px;
    font-size: 1.1rem;
    line-height: 1.6;
    color: var(--text);
    margin-bottom: 2rem;
}

.cta-buttons {
    display: flex;
    gap: 1.5rem;
}

.button-icon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
}

.hero-image {
    flex: 0 0 45%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 2rem 0 2rem;
    background-color: var(--background);
    border-radius: 10px;
}

.hero-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

/* Add these styles after the hero-text h2 styles */
.typewriter {
    color: var(--primary);
    display: inline-block;
}

/* Add these styles */
.hero-social-links {
    display: flex;
    gap: 1.5rem;
    margin-top: 2rem;
}

.hero-social-links .social-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero-social-links .social-icon img {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
    opacity: 0.8;
}

.hero-social-links .social-icon:hover img {
    transform: scale(1.1);
    opacity: 1;
}

.hero-actions {
    display: flex;
    align-items: center;
    gap: 3rem;
}

.hero-social-links {
    display: flex;
    gap: 1.5rem;
}

.hero-social-links .social-icon img {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
    opacity: 0.7;
}

.hero-social-links .social-icon:hover img {
    transform: scale(1.1);
    opacity: 1;
}

.hero-social-links-vertical {
    position: absolute;
    left: 2rem;
    top: 55%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.hero-social-links-vertical .social-icon img {
    width: 24px;
    height: 24px;
    transition: transform 0.3s ease;
    opacity: 0.7;
}

.hero-social-links-vertical .social-icon:hover img {
    transform: scale(1.1);
    opacity: 1;
}

/* Responsive Design */
@media (max-width: 1440px) {
    .hero-section {
        padding: 5vh 10vw 0;
    }
}

@media (max-width: 900px) {
    .hero-section {
        flex-direction: column;
        padding: 4rem 5%;
        text-align: center;
        min-height: calc(100vh - 12rem);
    }

    .hero-image {
        margin-top: 2rem;
        padding: 0;
        justify-content: center;
    }

    .hero-image img {
        max-width: 80%;
    }

    .hero-text h1 {
        font-size: 2.5rem;
    }

    .hero-text h2 {
        font-size: 2rem;
        justify-content: center;
    }

    .cta-buttons {
        justify-content: center;
        gap: 1rem;
    }

    .timeline-indicator {
        display: none;
    }

    .hero-social-links {
        justify-content: center;
        margin-top: 1.5rem;
    }

    .hero-actions {
        flex-direction: column;
        gap: 1.5rem;
    }
    
    .hero-social-links {
        justify-content: center;
    }

    .hero-social-links-vertical {
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: row;
        left: 0;
        opacity: 1;
        margin-bottom: 1rem;
    }

    .social-icon {
        margin: 0 0.5rem;
    }

    .hero-social-links-vertical .social-icon img{
        opacity: 1;
    }

    .button-icon {
        width: 18px;
        height: 18px;
    }
}