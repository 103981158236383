body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: overlay;  /* Makes scrollbar overlay content */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*FEATURE: Maybe look into implementing smooth-scrollbar from npm https://www.npmjs.com/package/smooth-scrollbar  */
/* Modern scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--primary50);  /* #8980f5 with opacity */
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--primary75);
}

/* Firefox scrollbar styling */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--primary50) transparent;
}

/* Hide scrollbar when not scrolling, but keep functionality */
@media (hover: hover) {
  ::-webkit-scrollbar-thumb {
    visibility: hidden;
  }
  
  ::-webkit-scrollbar-track {
    visibility: hidden;
  }

  *:hover::-webkit-scrollbar-thumb,
  *:hover::-webkit-scrollbar-track {
    visibility: visible;
  }
}
